import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { Icon, type IconName, type IconProps } from '@fv/client-components'
import { type PropsWithClassName } from '@fv/client-types'

import { CarrierTooltip } from '../CarrierTooltip'

type Props = PropsWithClassName<PropsWithChildren>
export const InputGroup = ({ children, className }: Props) => {
  return <div className={clsx(className, 'input-group')}>{children}</div>
}

type AddonProps = Pick<IconProps, 'transform'> & {
  icon: IconName
  tooltip?: string
  iconClassName?: string
}
export const InputGroupAddon = ({
  icon,
  tooltip,
  iconClassName,
  transform,
}: AddonProps) => {
  return (
    <div className="input-group-addon whitespace-nowrap flex items-center">
      <CarrierTooltip label={tooltip}>
        <Icon icon={icon} className={iconClassName} transform={transform} />
      </CarrierTooltip>
    </div>
  )
}
