import { Tooltip, type TooltipProps } from '@fv/client-components'

type Props = TooltipProps
export const CarrierTooltip = (props: Props) => {
  return (
    <Tooltip
      arrowClassName={() => `bg-gray-800`}
      className="border-1 [transition: opacity 0.3s ease-out] pointer-events-none  max-w-xs rounded-[0.3rem] border-solid border-transparent bg-gray-800 px-3 py-2 text-xs text-white opacity-90"
      {...props}
    >
      {props.children}
    </Tooltip>
  )
}
