import dayjs from 'dayjs'
import pluralize from 'pluralize'
import toast from 'react-hot-toast'

import {
  DelimitedContent,
  FvButton,
  FvLinkButton,
  Slash,
} from '@fv/client-components'
import { displayLocShipType } from '@fv/client-core'
import { type LoadDocument, type LoadHandlingUnit } from '@fv/client-types'
import {
  carrierDocTypes,
  carrierViewableDocTypes,
  type LoadDocumentType,
} from '@fv/models'
import { formatPhone } from '@fv/models/core'
import { CarrierTooltip } from '@/components/CarrierTooltip'
import { DateDisplay } from '@/components/shared/DateDisplay'
import { Accessorials } from '@/components/shared/EquipmentAccessorial'
import { StopAccessorials } from '@/components/shared/StopAccessorials'
import { documentTypes, supportMessage } from '@/constants'
import { usePrettyNumber } from '@/hooks/usePrettyNumber'
import { useTypeNames } from '@/hooks/useTypeNames'

import { type Opportunity } from '../../types/Opportunity'
import { useDeleteDocument } from './hooks/useDeleteDocument'
import { UploadDocumentField } from './UploadDocumentField'

type Props = {
  onClose: () => void
  opportunity: Opportunity
}

export const Upload = ({ onClose, opportunity }: Props) => {
  const {
    documents = [],
    equipmentRequested,
    items,
    loadId,
    locations,
    pickup,
    refNums = [],
  } = opportunity

  const { equipmentName, packageName } = useTypeNames()
  const deleteDocument = useDeleteDocument()
  const prettyNumber = usePrettyNumber()
  const allowedShipperDocs = [...carrierDocTypes, ...carrierViewableDocTypes]

  const shipperDocs = documents?.filter(
    d => d.source === 'shipper' && allowedShipperDocs.includes(d.type),
  )
  const allowedSystemDocs: LoadDocumentType[] = ['bol', 'rate-confirmation']
  const systemDocs = documents?.filter(
    d => d.source === 'system' && allowedSystemDocs.includes(d.type),
  )
  const carrierDocs = documents?.filter(d => d.source === 'carrier')
  const deleteSelectedDocument = (document: LoadDocument) => {
    if (deleteDocument.isLoading) return

    if (
      window.confirm('Are you sure you wish to delete the selected document?')
    ) {
      deleteDocument
        .mutateAsync({ document, loadId })
        .then(onClose)
        .catch(() => {
          toast.error(`Unable to remove document, ${supportMessage}`)
        })
    }
  }
  function getItemDetails(item: LoadHandlingUnit, isLastItem: boolean) {
    const {
      freightClass,
      height,
      length,
      quantity,
      weight: weightData,
      weightUOM,
      width,
      stackable,
    } = item
    const weight = prettyNumber(weightData)
    const packaging = packageName(item.type).toLowerCase()

    const classDetails = freightClass ? ` / Freight class: ${freightClass}` : ''
    const packageDetails = pluralize(packaging, quantity, true)

    let details = `${weight} ${weightUOM}. / `

    if (!length || !width || !height) {
      details += `${packageDetails}${classDetails}`
    } else {
      details += `${length} x ${width} x ${height} / ${packageDetails}${classDetails}`
    }

    if (stackable) details += ` / Stackable`

    if (!isLastItem) details += ' / '

    return details
  }

  return (
    <div className="upload-bol-ctn">
      <form action="" className="form-upload-bol">
        <UploadDocumentField onClose={onClose} opportunity={opportunity} />
      </form>

      <div className="upload-bol-info-ctn">
        {!!(
          carrierDocs.length ||
          shipperDocs?.length ||
          systemDocs?.length
        ) && (
          <div className="uploaded-documents-ctn">
            {!!carrierDocs?.length && (
              <div className="docs-list-ctn">
                <div>Uploaded to shipper</div>
                <hr />
                <ul className="divide-fv-gray flex-1 divide-y divide-dotted">
                  {carrierDocs.map(file => {
                    const docType = documentTypes.find(
                      t => t.value === file.type,
                    )
                    const label = docType?.text || file.fileName

                    return (
                      <li
                        className="flex flex-1 items-center py-2 leading-none"
                        key={file._id}
                      >
                        <CarrierTooltip label={getUploadDateTooltip(file)}>
                          <FvButton
                            icon="file"
                            className=""
                            onClick={() => {
                              window.open(file.url)
                            }}
                          >
                            {label}
                          </FvButton>
                        </CarrierTooltip>

                        <FvButton
                          className="ml-auto"
                          icon={deleteDocument.isLoading ? 'spinner' : 'trash'}
                          onClick={() => {
                            deleteSelectedDocument(file)
                          }}
                        />
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
            {!!shipperDocs?.length && (
              <div className="docs-list-ctn">
                <div>Shipper Provided</div>
                <hr />
                <ul className="general-list">
                  {shipperDocs.map(file => {
                    const docType = documentTypes.find(
                      t => t.value === file.type,
                    )
                    const label = docType?.text || file.fileName

                    return (
                      <li className="general-list__item" key={file._id}>
                        <ul className="general-list-flex">
                          <li className="general-list-flex__item flex-1">
                            <CarrierTooltip label={getUploadDateTooltip(file)}>
                              <a
                                className="standard-link standard-link--secondary block"
                                href="#"
                                onClick={e => {
                                  e.preventDefault()
                                  window.open(file.url)
                                }}
                              >
                                {label}
                              </a>
                            </CarrierTooltip>
                          </li>
                        </ul>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
            {!!systemDocs?.length && (
              <div className="docs-list-ctn">
                <div>Freightview Generated</div>
                <hr />
                <ul className="divide-fv-gray flex-1 divide-y divide-dotted">
                  {systemDocs.map(file => {
                    const docType = documentTypes.find(
                      t => t.value === file.type,
                    )
                    const label = docType?.text || file.fileName

                    return (
                      <li className="py-2" key={file._id}>
                        <CarrierTooltip label={getUploadDateTooltip(file)}>
                          <FvLinkButton
                            icon="file"
                            className="block"
                            onClick={() => {
                              window.open(file.url)
                            }}
                          >
                            {label}
                          </FvLinkButton>
                        </CarrierTooltip>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
          </div>
        )}

        <div className="flex-1">
          {locations.map(x => {
            const locationItems = items.filter(
              i => i.dropLocationSequence === x.sequence,
            )
            const shipType = displayLocShipType(x)

            const {
              contactEmail: email,
              contactName: name,
              contactPhone: phone,
            } = x

            return (
              <div className="upload-bol-info-ctn__to-from" key={x.sequence}>
                <div>
                  <DateDisplay
                    className="calendar-icon--stacked"
                    type={x.type}
                    value={x.stopDate}
                  />
                </div>
                <ul className="general-list general-list-fc-no-pad general-list-lc-no-border ml-4 flex-1">
                  <li className="general-list__item">
                    <p className="mb-0">
                      {x.company} / {x.address} /{' '}
                      {x.address2?.length ? x.address2 + ' /' : ''} {x.city},{' '}
                      {x.state} {x.postalCode} / {x.country.toUpperCase()}
                      {x.type === 'origin' &&
                        ` / ${equipmentName(equipmentRequested.type)}`}
                      <Accessorials
                        accessorials={equipmentRequested?.accessorials}
                      />
                    </p>
                  </li>
                  {locationItems.length > 0 &&
                    locationItems.map((item, index) => (
                      <li className="general-list__item" key={item._id}>
                        <span>
                          {getItemDetails(
                            item,
                            index === locationItems.length - 1,
                          )}
                        </span>
                      </li>
                    ))}

                  {x.type === 'origin' && (
                    <li className="general-list__item">
                      <p className="mb-0">
                        {!!refNums?.length && (
                          <span>
                            Shipper's # {refNums.map(n => n.value).join(' / ')}{' '}
                            /{' '}
                          </span>
                        )}
                        {pickup?.confirmationNumber && (
                          <span>Pickup # {pickup.confirmationNumber} / </span>
                        )}
                        Pickup hours: {x.opensAt} - {x.closesAt}
                      </p>
                    </li>
                  )}
                  {x.type === 'stop' && (
                    <li className="general-list__item">
                      <p className="mb-0">
                        Dock hours: {x.opensAt} - {x.closesAt}
                      </p>
                    </li>
                  )}
                  {x.type === 'destination' && (
                    <li className="general-list__item">
                      <p className="mb-0">
                        {!!x.refNums?.length && (
                          <>
                            <span>
                              Purchase order #{' '}
                              {x.refNums?.map(n => n.value).join(' / ')}
                            </span>
                            <span> / </span>
                          </>
                        )}
                        Delivery hours: {x.opensAt} - {x.closesAt}
                      </p>
                    </li>
                  )}

                  {(name || phone || email) && (
                    <li className="general-list__item">
                      <p className="mb-0">
                        {name}
                        {name && (phone || email) && ' / '}
                        {phone && (
                          <a
                            className="standard-link standard-link--secondary"
                            href={`tel:${phone}`}
                          >
                            {formatPhone(phone)}
                          </a>
                        )}
                        {phone && email && ' / '}
                        {email && (
                          <a
                            className="standard-link standard-link--secondary"
                            href={`mailto:${email}`}
                          >
                            {email}
                          </a>
                        )}
                      </p>
                    </li>
                  )}

                  {(shipType || !!x.accessorials?.length) && (
                    <li className="general-list__item">
                      <DelimitedContent delimiter={<Slash />}>
                        {shipType}
                        {!!x.accessorials?.length && (
                          <StopAccessorials
                            accessorials={x.accessorials ?? []}
                          />
                        )}
                      </DelimitedContent>
                    </li>
                  )}
                  {x.instructions && (
                    <li className="general-list__item">
                      <p className="mb-0">{x.instructions}</p>
                    </li>
                  )}
                </ul>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

function getUploadDateTooltip(file: LoadDocument) {
  if (file.uploadDate) {
    return `Uploaded ${dayjs.utc(file.uploadDate).local().format('YYYY-MM-DD')}`
  }
  return 'Upload date unavailable'
}
