import clsx from 'clsx'
import { useState } from 'react'
import toast from 'react-hot-toast'

import { FvButton, FvLinkButton, Icon } from '@fv/client-components'
import { type LoadDocument } from '@fv/client-types'
import { CarrierModal } from '@/components/shared/CarrierModal'
import { documentTypes, supportMessage } from '@/constants'

import { CarrierTooltip } from '../../components/CarrierTooltip'
import { type Opportunity } from '../../types/Opportunity'
import { MessageFlyOut } from '../messaging/MessageFlyOut'
import { useOppListingActions } from '../opportunities/OppListingContextProvider'
import { Upload } from '../upload/Upload'
import { useDeclineOpportunity } from './hooks/useDeclineOpportunity'
import { useUndeclineOpportunity } from './hooks/useUndeclineOpportunity'
import { MultiStopTrackingList } from './MultiStopTrackingList'
import { SelectedPriceDisplay } from './SelectedPriceDisplay'
import { Share } from './Share'

type Props = {
  opportunity: Opportunity
  onToggleQuoteForm?: () => void
  isExpired?: boolean
}
export const CardActions = ({
  opportunity,
  onToggleQuoteForm,
  isExpired,
}: Props) => {
  const {
    bol,
    cheapestQuoteAmount,
    cheapestQuoteCurrency,
    documents,
    isArchived,
    isLiveLoad,
    isLowest,
    loadId,
    status,
    showPricingIndicator,
  } = opportunity

  const { setMessagesOpen } = useOppListingActions()

  const [showShare, setShowShare] = useState(false)
  const [showUpload, setShowUpload] = useState(false)
  const decline = useDeclineOpportunity(status)
  const undecline = useUndeclineOpportunity()

  const declineQuoteRequest = () => {
    if (decline.isLoading) return
    if (window.confirm('Are you sure you want to decline this opportunity?')) {
      decline.mutateAsync({ loadId }).catch(() => {
        toast.error(`Unable to decline opportunity, ${supportMessage}`)
      })
    }
  }

  const undeclineQuoteRequest = () => {
    if (undecline.isLoading) return
    undecline.mutateAsync({ loadId }).catch(() => {
      toast.error(
        `Unable to move back to open opportunities, ${supportMessage}`,
      )
    })
  }

  const notLost = status !== 'lost'
  const showComparison = showPricingIndicator !== false && notLost
  const canViewBOL = status === 'awarded' || status === 'confirmed'
  const canDecline = status === 'open' || status === 'offered'
  const canShare = status !== 'declined' && status !== 'lost'
  const bols = documents?.filter(d => d.type === 'bol') ?? []
  const fvBol =
    bols.find(b => b.source === 'shipper') ??
    bols.find(b => b.source === 'system')

  const allowedDocs = [
    'bol',
    'proof-of-delivery',
    'signed-bol',
    'invoice',
    'other',
  ]
  const allowedSystemDocs = ['rate-confirmation']
  const visibleDocs = documents?.filter(
    d =>
      d.source === 'carrier' ||
      (allowedDocs.includes(d.type) && d.source !== 'system') ||
      (allowedSystemDocs.includes(d.type) && d.source === 'system'),
  )

  const showLoadStatus =
    status === 'open' ||
    status === 'declined' ||
    status === 'offered' ||
    status === 'lost'

  return (
    <div
      className={clsx('shipment-item-ancillary-actions', {
        '!order-2 mb-0 mt-4': status === 'open' || status === 'declined',
      })}
    >
      <ul className="general-list-flex">
        {cheapestQuoteAmount && (
          <li className="general-list-flex__item">
            <SelectedPriceDisplay
              amount={cheapestQuoteAmount}
              currency={cheapestQuoteCurrency}
              isLowest={isLowest}
              showComparison={showComparison}
            />
          </li>
        )}

        <li
          className={`general-list-flex__item general-list-flex__item--messages${
            cheapestQuoteAmount ? '' : '-fc'
          }`}
        >
          <MessageFlyOut openMessages={() => setMessagesOpen(true)} />
        </li>

        {canShare && (
          <li className="general-list-flex__item">
            <FvLinkButton
              theme="plain"
              className="standard-link pl-0 pr-0"
              onClick={() => setShowShare(true)}
              icon="share"
            >
              <span className="quoting-share-text">Share</span>
            </FvLinkButton>
          </li>
        )}

        {canDecline && (
          <li className="general-list-flex__item pl-0">
            <FvLinkButton
              theme="plain"
              className="standard-link"
              onClick={declineQuoteRequest}
              icon={decline.isLoading ? 'spinner' : 'ban'}
            >
              <span className="opportunities-decline-text">Decline</span>
            </FvLinkButton>
          </li>
        )}

        {status === 'declined' && (
          <li className="general-list-flex__item">
            <CarrierTooltip label="Move back to open queue">
              <FvLinkButton
                theme="plain"
                className="standard-link"
                onClick={undeclineQuoteRequest}
                disabled={isExpired}
                icon={undecline.isLoading ? 'spinner' : 'trash-restore-alt'}
              >
                <span className="opportunities-move-text">Move back</span>
              </FvLinkButton>
            </CarrierTooltip>
          </li>
        )}

        {(fvBol || bol?.status === 'pending') && canViewBOL && (
          <li className="general-list-flex__item">
            <a className="standard-link" target="_bol" href={fvBol?.url}>
              <Icon icon={bol?.status === 'pending' ? 'spinner' : 'file-alt'} />
              <span className="active-viewbol-text">View BOL</span>
              <span className="active-viewbol-text-short">BOL</span>
            </a>
          </li>
        )}

        {status === 'confirmed' && (
          <li className="general-list-flex__item general-list-flex__item--uploadbol flex-default">
            <a
              className="standard-link"
              onClick={() => setShowUpload(true)}
              target="_bol"
            >
              <Icon icon="cloud-upload" />
              <span className="active-uploadbol-text-long">
                Upload / View docs
              </span>
              <span className="active-uploadbol-text-short">Docs</span>
            </a>

            {visibleDocs?.length > 0 && (
              <CarrierTooltip
                label={`Currently uploaded > ${composeDocumentNames(
                  visibleDocs,
                )}`}
              >
                <a
                  className="standard-link standard-link--secondary badge"
                  href="#"
                  onClick={e => e.preventDefault()}
                >
                  {visibleDocs.length}
                </a>
              </CarrierTooltip>
            )}
          </li>
        )}

        {(showLoadStatus || onToggleQuoteForm) && (
          <li className="ml-auto flex">
            <span className="ll-qo-text">
              {isLiveLoad ? 'Live load' : 'Quote only'}
            </span>

            {onToggleQuoteForm && (
              <FvButton
                className="btn ml-4"
                onClick={onToggleQuoteForm}
                type="button"
                icon="list"
                theme="default"
              >
                <span>Quote</span>
              </FvButton>
            )}
          </li>
        )}

        {status === 'confirmed' && !isArchived && (
          <MultiStopTrackingList opportunity={opportunity} />
        )}
      </ul>
      {showUpload && (
        <CarrierModal onClose={() => setShowUpload(false)}>
          <Upload
            onClose={() => setShowUpload(false)}
            opportunity={opportunity}
          />
        </CarrierModal>
      )}
      {showShare && (
        <CarrierModal onClose={() => setShowShare(false)}>
          <Share loadId={loadId} onClose={() => setShowShare(false)} />
        </CarrierModal>
      )}
    </div>
  )
}

function composeDocumentNames(docs: LoadDocument[] = []) {
  const docTypes = docs.reduce((types, doc) => {
    const name = documentTypes.find(t => t.value === doc.type)?.text
    return !name || types.includes(name) ? types : types.concat(name)
  }, [])

  return docTypes.join(' / ')
}
